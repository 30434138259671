@import 'src/theme/colors';

@font-face {
  font-family: 'Satoshi';
  src: url('assets/fonts/Satoshi-Variable.woff2') format('woff2'),
    url('assets/fonts/Satoshi-Variable.woff') format('woff'),
    url('assets/fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 800;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('assets/fonts/Satoshi-VariableItalic.woff2') format('woff2'),
    url('assets/fonts/Satoshi-VariableItalic.woff') format('woff'),
    url('assets/fonts/Satoshi-VariableItalic.ttf') format('truetype');
  font-weight: 300 800;
  font-style: italic;
}

@font-face {
  font-family: 'DM Serif';
  src: url('assets/fonts/DMSerifDisplay-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'DM Serif';
  src: url('assets/fonts/DMSerifDisplay-Italic.ttf') format('truetype');
  font-style: italic;
}

@import url('./theme/_notifications.scss');
