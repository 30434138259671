:root {
  --toastify-color-success: #00b74a;
  --toastify-toast-width: auto;
}

.Toastify__toast-theme--colored {
  &.Toastify__toast--success {
    background-color: #effff6;
    color: #353754;
    border: 1px solid #00b74a;
    border-radius: 10px;
    box-shadow: none;
  }

  &.Toastify__toast--error {
    background-color: #ffeded;
    color: #353754;
    border: 1px solid #ff0000;
    border-radius: 10px;
    box-shadow: none;
  }

  .Toastify__toast-icon {
    width: 41px;
    height: 37px;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin: 10px;
  }
}
