// primary
$yellow: #ffe664;
$night: #343754;
$beige: #f7f2ed;
$white: #ffffff;

// secondary
$blush: #f8d0c5;
$grey: #f0f0f0;
$tan: #f0e6dc;
$pale-yellow: #fcf6d6;

// twilight shades
$twilight-light: #727589;
$twilight-med: #63667c;
$twilight-dark: #4b4e68;
