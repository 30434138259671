@import 'src/theme/breakpoints';
@import 'src/theme/colors';
@import 'src/theme/utilities';

.h2,
.navigation {
  font-display: swap;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.2;
  margin: 24px;

  @media screen and (min-width: $bp-tablet-portrait) {
    font-size: 36px;
    margin: 30px 24px;
  }
}

.h3 {
  font-display: swap;
  font-size: 18px;
  font-weight: 400;

  @media screen and (min-width: $bp-tablet-landscape) {
    font-size: 24px;
  }
}

.link {
  font-display: swap;
  font-size: 18px;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
    font-weight: 600;
    text-decoration: underline;
  }
}

// :not() excludes the v2 components so we don't need to override these styles
input:not(.v2) {
  border-radius: 0;
  -webkit-appearance: none;
}

// :not() excludes the v2 components so we don't need to override these styles
input:not(.v2):focus {
  outline: none;
  border-bottom: 3px solid $yellow;
  border-bottom-color: $yellow;
}

// :not() excludes the v2 components so we don't need to override these styles
input:not(.v2)[type='checkbox'] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  &:focus {
    &::after {
      color: $beige;
      border-bottom: 3px solid $yellow;
      padding-left: 4px;
      @media screen and (min-width: $bp-tablet-portrait) {
        border-bottom: 3px auto $yellow;
      }
    }
  }
}

.type__label {
  font-display: swap;
  font-size: 14px;
}

.type__body {
  font-display: swap;
  font-size: 18px;
  font-weight: 100;
}

.type__address {
  font-display: swap;
  font-size: 14px;
  font-weight: 100;
  @media screen and (min-width: $bp-desktop) {
    font-size: 18px;
  }
}

.type__input__label {
  font-display: swap;
  font-size: 14px;
}

.type__input__field {
  font-display: swap;
  font-size: 16px;
  color: $night;

  @media screen and (min-width: $bp-tablet-portrait) {
    font-size: 24px;
  }
}

.type__option {
  font-display: swap;
  font-size: 18px;
}

.type__button {
  font-display: swap;
  font-size: 14px;
}

.type__link__primary {
  font-display: swap;
  font-size: 14px;
}

.type__link__secondary {
  font-display: swap;
  font-size: 18px;
  @media screen and (min-width: $bp-tablet-portrait) {
    font-size: 24px;
  }
  @media screen and (min-width: $bp-tablet-landscape) {
    font-size: 18px;
  }
  @media screen and (min-width: $bp-desktop) {
    font-size: 24px;
  }
}
