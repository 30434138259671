.ReactCrop {
  background-color: white;
}
.ReactCrop__crop-selection {
  border: 2px white solid;
  border-image-source: none;
  border-radius: 50%;
  box-shadow: 0 0 0 9999em rgba(247, 242, 237, 0.5);
}
.ReactCrop__drag-handle {
  background-color: white;
  border-radius: 50%;
  height: 15px;
  opacity: 0.9;
  width: 15px;
}
