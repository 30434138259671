@import 'src/theme/colors';
@import 'src/theme/utilities';

.button {
  background-color: $yellow;
  border: 1px solid;
  border-color: $yellow;
  border-radius: 50px;
  padding: 15px 20px;
  text-align: center;
  width: 100%;
  height: 49.6px;
  max-width: 340px;
  min-width: 160px;
  cursor: pointer;
  box-shadow: $drop-shadow;
  transition: background-color 500ms;
  transition: border-color 500ms;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: 0;
  }

  &__disabled {
    background-color: $twilight-dark;
    border: 1px solid;
    border-color: $night;

    &:hover {
      cursor: not-allowed;
      opacity: 1;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__loader {
    margin: 0 auto;
  }
}
